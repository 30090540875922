
					@import './src/sass/variables';
					@import './src/sass/mixins';
				



































































.transcript {
	padding: 19px;
	background-color: $color__white;
	border-radius: $border_radius;
}

.transcript-open {
	.toggle-transcript::v-deep svg {
		transform: rotate(180deg);
	}
}

.toggle-transcript::v-deep {
	svg {
		transition: transform ease 0.1s;
	}

	button {
		padding: 0;
	}
}
